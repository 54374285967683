.social__icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 75px;
    height: 75px;
    border-radius: 50%;
    background-color: transparent;
    transition: background-color 0.3s ease;
}

.social__icon:hover {
    background-color: #f5f5f5;
    border: 2px solid #e2e2e2;
}

.social__icon img {
    display: block;
    width: 50%;
    height: auto;
}

/* resume button */
/* .button__resume {
    border: 1px solid var(--darkblue-hover);
    border-color: var(--blue-primary);
    padding: 0.4rem;
    margin-right: 1rem;
    min-width: 110px;
    height: 100%;
    min-height: 3rem;
    border-radius: 12.5px;
    color: #fff;
    font-weight: 500;
    background-color: var(--blue-primary);
    -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: box-shadow 200ms ease, transform 200ms ease;
    box-shadow: 0px 0px 0 var(--darkblue-hover);
    position: relative;
}

.button__resume:hover {
    -webkit-transform: translate(3px, -3px);
    -moz-transform: translate(3px, -3px);
    -ms-transform: translate(3px, -3px);
    transform: translate(3px, -3px);
    box-shadow: -6px 6px 0 var(--darkblue-hover);
} */

.button__resume {
    border: 1px solid var(--darkblue-hover);
    border-color: var(--blue-primary);
    padding: 0.1rem;
    margin-right: 1rem;
    min-width: 140px;
    height: 100%;
    min-height: 3.5rem;
    border-radius: 12.5px;
    color: #f5f5f5;
    font-family: PoppinsSemibold, sans-serif;
    font-size: 1.25rem;
    background-color: var(--blue-primary);
    -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: box-shadow 200ms ease, transform 200ms ease;
    box-shadow: 0px 0px 0 var(--darkblue-hover);
    position: relative;
}

.button__resume:hover {
    -webkit-transform: translate(3px, -3px);
    -moz-transform: translate(3px, -3px);
    -ms-transform: translate(3px, -3px);
    transform: translate(3px, -3px);
    box-shadow: -6px 6px 0 var(--darkblue-hover);
}

/* contact button */
/* .button__contact {
    border: 1px solid var(--darkyellow-hover);
    border-color: var(--yellow-primary);
    padding: 0.4rem;
    margin-right: 1rem;
    min-width: 110px;
    height: 100%;
    min-height: 3rem;
    border-radius: 12.5px;
    color: #fff;
    font-weight: 500;
    background-color: var(--yellow-primary);
    -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: box-shadow 200ms ease, transform 200ms ease;
    box-shadow: 0px 0px 0 var(--darkyellow-hover);
    position: relative;
}

.button__contact:hover {
    -webkit-transform: translate(3px, -3px);
    -moz-transform: translate(3px, -3px);
    -ms-transform: translate(3px, -3px);
    transform: translate(3px, -3px);
    box-shadow: -6px 6px 0 var(--darkyellow-hover);
} */

.button__contact {
    border: 1px solid var(--darkyellow-hover);
    border-color: var(--yellow-primary);
    padding: 0.1rem;
    margin-right: 1rem;
    min-width: 140px;
    height: 100%;
    min-height: 3.5rem;
    border-radius: 12.5px;
    color: #f5f5f5;
    font-family: PoppinsSemibold, sans-serif;
    font-size: 1.25rem;
    background-color: var(--yellow-primary);
    -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: box-shadow 200ms ease, transform 200ms ease;
    box-shadow: 0px 0px 0 var(--darkyellow-hover);
    position: relative;
}

.button__contact:hover {
    -webkit-transform: translate(3px, -3px);
    -moz-transform: translate(3px, -3px);
    -ms-transform: translate(3px, -3px);
    transform: translate(3px, -3px);
    box-shadow: -6px 6px 0 var(--darkyellow-hover);
}