.nav {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.nav img {
    width: 150px;
}

.nav a {
    text-decoration: none;
}

.nav__links {
    list-style-type: none;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
}

.nav__link {
    font-size: 1.2rem;
    font-weight: 500;
    color: var(--blue-primary);
    padding: 10px;
    cursor: pointer;
    position: relative;
}

.nav__link::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background-color: var(--orangebrown-primary);
    transition: width 0.3s ease;
    transform-origin: left;
}

.nav__link:hover {
    color: var(--orangebrown-primary);
    transition: 0.2s;
}

.nav__link:hover::before {
    width: 100%;
}

.nav__button {
    background-color: var(--orangebrown-primary);
    color: #fff;
    border-radius: 0.75rem;
    padding: 10px 15px;
}

.nav__button:hover {
    background-color: transparent;
    color: var(--orangebrown-primary);
    border: 2px solid var(--orangebrown-primary);
}