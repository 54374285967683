/* demo button */
.button__demo {
    border: 1px solid var(--darkgreen-hover);
    border-color: var(--green-primary);
    padding: 0.1rem;
    margin-right: 1rem;
    min-width: 120px;
    height: 100%;
    min-height: 3rem;
    border-radius: 12.5px;
    color: #f5f5f5;
    font-family: PoppinsSemibold, sans-serif;
    font-size: 1.25rem;
    background-color: var(--green-primary);
    -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: box-shadow 200ms ease, transform 200ms ease;
    box-shadow: 0px 0px 0 var(--darkgreen-hover);
    position: relative;
}

.button__demo:hover {
    -webkit-transform: translate(3px, -3px);
    -moz-transform: translate(3px, -3px);
    -ms-transform: translate(3px, -3px);
    transform: translate(3px, -3px);
    box-shadow: -6px 6px 0 var(--darkgreen-hover);
}

/* more projects */
.button__moreProjects {
    border: 1px solid var(--darkorange-hover);
    border-color: var(--lightorange-primary);
    padding: 0.1rem;
    margin-right: 1rem;
    min-width: 240px;
    height: 100%;
    min-height: 4rem;
    border-radius: 12.5px;
    color: #f5f5f5;
    font-family: PoppinsSemibold, sans-serif;
    font-size: 1.25rem;
    background-color: var(--lightorange-primary);
    -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: box-shadow 200ms ease, transform 200ms ease;
    box-shadow: 0px 0px 0 var(--darkorange-hover);
    position: relative;
}

.button__moreProjects:hover {
    -webkit-transform: translate(3px, -3px);
    -moz-transform: translate(3px, -3px);
    -ms-transform: translate(3px, -3px);
    transform: translate(3px, -3px);
    box-shadow: -6px 6px 0 var(--darkorange-hover);
}

/* reverseddemo button */
.button__reverseddemo {
    border: 1px solid var(--darklightblue-hover);
    border-color: var(--lightblue-primary);
    padding: 0.1rem;
    margin-right: 1rem;
    min-width: 120px;
    height: 100%;
    min-height: 3rem;
    border-radius: 12.5px;
    color: #f5f5f5;
    font-family: PoppinsSemibold, sans-serif;
    font-size: 1.25rem;
    background-color: var(--lightblue-primary);
    -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: box-shadow 200ms ease, transform 200ms ease;
    box-shadow: 0px 0px 0 var(--darklightblue-hover);
    position: relative;
}

.button__reverseddemo:hover {
    -webkit-transform: translate(-3px, -3px);
    -moz-transform: translate(-3px, -3px);
    -ms-transform: translate(-3px, -3px);
    transform: translate(-3px, -3px);
    box-shadow: 6px 6px 0 var(--darklightblue-hover);
}

.project__image {
    width: 85%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.3s ease;
}

.project__image:hover {
    transform: scale(1.04);
}

.project__title {
    font-size: 2.25rem; /* Adjust font size as needed */
    font-weight: 800;
    margin-bottom: 1rem;
}

.project__description {
    font-size: 1.15rem; /* Adjust font size as needed */
    font-weight: 500;
    margin-bottom: 1.5rem;
}

.project__technologies {
    font-size: 1.15rem;
    font-weight: 500;
}

.project__icons {
    width: 35px;
    height: auto;
}