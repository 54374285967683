/* more projects */
.button__home {
  border: 1px solid var(--darkred-hover);
  border-color: var(--lightred-primary);
  padding: 0.1rem;
  margin-right: 1rem;
  min-width: 200px;
  height: 100%;
  min-height: 4rem;
  border-radius: 12.5px;
  color: #f5f5f5;
  font-family: PoppinsSemibold, sans-serif;
  font-size: 1.25rem;
  background-color: var(--lightred-primary);
  -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
  transition: box-shadow 200ms ease, transform 200ms ease;
  box-shadow: 0px 0px 0 var(--darkred-hover);
  position: relative;
}

.button__home:hover {
  -webkit-transform: translate(3px, -3px);
  -moz-transform: translate(3px, -3px);
  -ms-transform: translate(3px, -3px);
  transform: translate(3px, -3px);
  box-shadow: -6px 6px 0 var(--darkred-hover);
}

.project__image {
  width: 85%;
  height: auto;
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.project__image:hover {
  transform: scale(1.04);
}

.project__title {
  font-size: 2.25rem; /* Adjust font size as needed */
  font-weight: 800;
  margin-bottom: 1rem;
}

.project__description {
  font-size: 1.15rem; /* Adjust font size as needed */
  font-weight: 500;
  margin-bottom: 1.5rem;
}

.project__technologies {
  font-size: 1.15rem;
  font-weight: 500;
}

.project__icons {
  width: 35px;
  height: auto;
}