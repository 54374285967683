.layout {
    padding: 5px 12.5vw;
}

@media (max-width: 1600px) {
    .layout {
        padding: 20px 10vw;
    }
}

@media (max-width: 700px) {
    .layout {
        padding: 20px;
    }
}