@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: PoppinsRegular;
  src: url(./assets/fonts/Poppins-Regular.ttf);
}

@font-face {
  font-family: PoppinsSemiBold;
  src: url(./assets/fonts/Poppins-SemiBold.ttf);
}

@font-face {
  font-family: PoppinsBold;
  src: url(./assets/fonts/Poppins-Bold.ttf);
}

:root {
  --blue-primary: #00305f;
  --orangebrown-primary: #bc7a29;
  --textcolor-primary: #707070;
  --yellow-primary: #fed32b;
  --green-primary: #00c936;
  --lightblue-primary: #19a9fc;
  --lightred-primary: #ff636b;
  --lightorange-primary: #ffaf0f;

  --darkblue-hover: #2b2b2b;
  --darkyellow-hover: #654e00;
  --darkgreen-hover: #00631b;
  --darklightblue-hover: #004f7d;
  --darkred-hover: #870007;
  --darkorange-hover: #996600;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: PoppinsRegular, sans-serif;
}

p {
  color: var(--textcolor-primary);
}

.bluetext {
  color: var(--blue-primary);
}

.orangetext {
  color: var(--orangebrown-primary);
}