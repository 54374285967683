/* redcontact button */
/* .button__redcontact {
    border: 1px solid var(--darkred-hover);
    border-color: var(--lightred-primary);
    padding: 0.4rem;
    margin-right: 1rem;
    min-width: 110px;
    height: 100%;
    min-height: 3rem;
    border-radius: 12.5px;
    color: #fff;
    font-weight: 500;
    background-color: var(--lightred-primary);
    -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: box-shadow 200ms ease, transform 200ms ease;
    box-shadow: 0px 0px 0 var(--darkred-hover);
    position: relative;
}

.button__redcontact:hover {
    -webkit-transform: translate(3px, -3px);
    -moz-transform: translate(3px, -3px);
    -ms-transform: translate(3px, -3px);
    transform: translate(3px, -3px);
    box-shadow: -6px 6px 0 var(--darkred-hover);
} */

.button__redcontact {
    border: 1px solid var(--darkred-hover);
    border-color: var(--lightred-primary);
    padding: 0.1rem;
    margin-right: 1rem;
    min-width: 140px;
    height: 100%;
    min-height: 3.5rem;
    border-radius: 12.5px;
    color: #f5f5f5;
    font-family: PoppinsSemibold, sans-serif;
    font-size: 1.25rem;
    background-color: var(--lightred-primary);
    -webkit-transition: box-shadow 200ms ease, -webkit-transform 200ms ease;
    transition: box-shadow 200ms ease, transform 200ms ease;
    box-shadow: 0px 0px 0 var(--darkred-hover);
    position: relative;
}

.button__redcontact:hover {
    -webkit-transform: translate(3px, -3px);
    -moz-transform: translate(3px, -3px);
    -ms-transform: translate(3px, -3px);
    transform: translate(3px, -3px);
    box-shadow: -6px 6px 0 var(--darkred-hover);
}